<template>
 <div>
    <div class="botnav">
      <div class="navcco">
        <div class="navcontents">
          <div>
            <!-- 关于我们 -->
            <p
              v-html="$t('language.abloutwe')"
              @click="gopage('鲜美烟台', 'deliciousyt')"
            ></p>
            <p class="g">|</p>
            <!-- 展馆指南 -->
            <p
              v-html="$t('language.PavilionGuide')"
              @click="gopage('展馆概况', 'exhibition')"
            ></p>
            <p class="g">|</p>
            <!-- 展会动态 -->
            <p
              v-html="$t('language.ExhibitionTrends')"
              @click="gopage('新闻动态', 'TradeShowTrends')"
            ></p>
            <p class="g">|</p>
            <!-- 展会服务 -->
            <p
              v-html="$t('language.ExhibitionServices')"
              @click="gopage('餐饮服务', 'ExhibitionServices')"
            ></p>
            <p class="g">|</p>
            <!-- 资料下载 -->
            <p
              v-html="$t('language.DataDownload')"
              @click="gopage('展馆平面图', 'datadownload')"
            ></p>
            <p class="g">|</p>
            <!-- 周边服务 -->
            <p
              v-html="$t('language.PeripheralServices')"
              @click="gopage('交通指南', 'traffic')"
            ></p>
          </div>
          <!-- 友情链接：烟台业达城市发展集团有限公司 -->
          <p class="explain">友情链接：<a href="http://ytydcf.com" target="black" style="color:#ffffff">烟台业达城市发展集团有限公司</a></p>
          <!-- <p class="explain">{{ $t("language.Link") }}</p> -->
          <!-- 烟台业达国际会展有限公司 烟台业达文旅有限公司 -->
          <!-- <p class="explain">{{ $t("language.Linkx") }}</p> --><br />
          <p class="explain" v-html="$t('language.shandongyantai')"></p>
        </div>
        <div class="relation">
          <!-- 烟台八角国际会展中心 -->
          <!-- <p>{{ $t("language.ExhibitionCore") }}</p> -->
          <p>{{ $t("language.Telephone") }}  <span style="margin:0 5px 0 3px;">:</span>  0535-6959157</p>
          <!-- 地址: 烟台经济技术开发区八角湾 -->
          <!-- <p v-html="$t('language.shandongyantai')"></p> -->
          <!-- <p>投诉电话 <span style="margin:0 5px 0 3px;">:</span>  0535-6939167</p> -->
          <p>{{$t("language.Mailbox")}}  <span style="margin:0 5px 0 3px;">:</span> ytbjw@ybicec.com</p>
        </div>
        <div class="relationequipment">
          <!-- 关注 -->
          <p>
            <img src="../assets/image/底部导航-微博.png" alt="" /><img
              src="../assets/image/底部导航-微信.png"
              alt=""
            />
          </p>
        </div>
        <div class="relationcode">
          <img src="../assets/image/二维码.png" alt="" />
          <!-- 微信公众号 -->
          <p>{{ $t("language.WeChatOfficialAccount") }}</p>
        </div>
      </div>
    </div>
    <div class="indexbottom">
      {{ $t("language.Record") }}
      <img src="../assets/image/beian.png" alt=""  style="width:12px;height:12px;"/>
      <a style="color: #ffffff;margin-left: 4px;" href="https://beian.mps.gov.cn/#/query/websearch?code=37069302889046" rel="noreferrer" target="_blank">鲁公网安备37069302889046</a> 
    </div>
 </div>
</template>

<script>
export default {
methods:{
  gopage(title, val) {
      this.$router.push({ path: val, query: { path: title } });
    },
}
}
</script>

<style scoped>
    .titles {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 375px;
  margin-left: 5px;
  font-size: 20px;
  margin-top: 25px;
}
.conts {
  height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 14px;
  width: 368px;
  margin: 33px auto 37px;
  color: #666;
}
.bots {
  width: 368px;
  font-size: 12px;
  color: #666;
}
.bots span:nth-of-type(1) {
  float: left;
  padding-left: 7px;
  box-sizing: border-box;
}
.bots span:nth-of-type(2) {
  float: right;
}
.botnav {
  width: 100%;
  background: #333333;
  height: 120px;
}
.indexbottom {
  width: 100%;
  height: 37px;
  background: #1a1a1a;
  text-align: center;
  line-height: 37px;
  font-size: 12px;
  color: #fff;
}
.relationcode p {
  font-size: 13px;
  color: #fff;
}
.relationcode {
  width: 77px;
  height: 99px;
  float: right;
  text-align: center;
  margin-top: -13px;
}
.relationequipment p:nth-of-type(1) img {
  margin-right: 30px;
}
.relationequipment p:nth-of-type(1) img:nth-last-of-type(1) {
  margin-right: 0;
}
.relationequipment {
  width: 115px;
  height: 60px;
  float: left;
  margin-right: 150px;
}
.relation p:nth-of-type(1) {
  font-size: 14px;
  margin-bottom: 15px;
}
.relation p:nth-of-type(2) {
  font-size: 14px;
  margin-bottom: 13px;
}
.relation p:nth-of-type(3) {
  font-size: 14px;
}
.relation {
  width: 310px;
  height: 90px;
  float: left;
  color: #fff;
  margin-right: 70px;
}
.explain:nth-of-type(1) {
  margin-top: 10px;
}
.explain {
  line-height: 30px;
}
.navcontents p {
  font-size: 15px;
  color: #fff;
  float: left;
  cursor: pointer;
}
.navcontents .g {
  font-size: 15px;
  color: #545454;
  margin: 0 15px;
}
.navcontents {
  width: 565px;
  height: 90px;
  margin-right: 113px;
  float: left;
}
.navcco {
  width: 1400px;
  margin: 0 auto;
  height: 100%;
  padding-top: 25px;
  box-sizing: border-box;
}
</style>