<template>
  <div class="car">
    <div class="add" :height="windowheight">
       <div class="slideshow">

       </div>
       <img class="imgs" src="../assets/image/下滑.png" alt="" />
    </div>
    <div class="nav" :style="{ borderBottom: opacitys >= 1 ? '0' : '0' }">
        <img
          class="logo" @click="goindexpage"
          :style="{ opacity: opacitys }"
          src="../assets/image/LOGO.png"
          alt=""
        />
        <div class="navcont" :style="{ opacity: opacitys}">
          <!-- 关于我们 -->
          <p @mouseenter="open(1)" @mouseleave="close">
           <span @click="skips('/','deliciousyt')" v-html="$t('language.abloutwe')"></span><i class="el-icon-arrow-down"></i
            ><span class="open" :style="{ display: ablout }">
              <span @click="skips('鲜美烟台','deliciousyt')">{{ $t("language.DeliciousYantai") }}</span>
              <span @click="skips('活力开发区','deliciousyt')">{{
                $t("language.DynamicDevelopmentZone")
              }}</span>
              <span @click="skips('公司介绍','deliciousyt')">{{
                $t("language.CompanyIntroduction")
              }}</span>
              <span @click="skips('联系我们','deliciousyt')">{{ $t("language.ContactUs") }}</span>
            </span>
          </p>
          <!-- 展馆指南 -->
          <p @mouseenter="open(2)" @mouseleave="close">
            <span  @click="skips('/','exhibition')" v-html="$t('language.PavilionGuide')"></span><i class="el-icon-arrow-down"></i
            ><span class="open" :style="{ display: fingerpost }">
              <span @click="skips('展馆概况','exhibition')">{{
                $t("language.ExhibitionHallSurvey")
              }}</span>
              <span @click="skips('展览场地','exhibition')">{{ $t("language.exhibitionGround") }}</span>
              <span @click="skips('会议中心','exhibition')">{{ $t("language.ConferenceCenter") }}</span>
              <span @click="skips('精美组图','exhibition')">{{ $t("language.ExquisiteGroupPictures") }}</span>
              <span @click="toVr('VR导览','exhibition')">{{ $t("language.vrView") }}</span>
            </span>
          </p>
          <!-- 展会动态 -->
          <p @mouseenter="open(3)" @mouseleave="close">
            <span @click="skips('/','TradeShowTrends')" v-html="$t('language.ExhibitionTrends')"></span><i class="el-icon-arrow-down"></i
            ><span class="open" :style="{ display: dynamic }">
            <span @click="skips('新闻动态','TradeShowTrends')">
            {{ $t("language.NewInformation") }}</span>
            <span  @click="skips('精彩瞬间','TradeShowTrends')">{{ $t("language.WonderfulMoment") }}</span>
            <span  @click="skips('展会排期','TradeShowTrends')">{{ $t("language.ExhibitionScheduling") }}</span>
            <span  @click="skips('通知公告','TradeShowTrends')">{{ $t("language.NoticeNotice") }}</span>
<!--             <span  @click="skips('办展流程','TradeShowTrends')">{{ $t("language.ExhibitionProcess") }}</span>-->
            </span>
          </p>
          <!-- 展会服务 -->
          <p @mouseenter="open(4)" @mouseleave="close">
             <span @click="skips('/','ExhibitionServices')" v-html="$t('language.ExhibitionServices')"></span>
            <i class="el-icon-arrow-down"></i>
            <span class="open" :style="{ display: serve }">
              <span @click="skips('餐饮服务','ExhibitionServices')">{{ $t('language.FoodAndBeverages') }}</span>
              <span @click="skips('展具租赁','ExhibitionServices')">{{ $t('language.ExhibitionEquipmentRental') }}</span>
              <span @click="skips('广告服务','ExhibitionServices')"> {{ $t('language.AdvertisingServices') }}</span>
              <span @click="skips('搭建服务','ExhibitionServices')"> {{ $t('language.BuildService') }}</span>
            </span>
          </p>
          <!-- 资料下载 -->
          <p @mouseenter="open(5)" @mouseleave="close">
            <span @click="skips('/','datadownload')" v-html="$t('language.DataDownload')"></span><i class="el-icon-arrow-down"></i
            ><span class="open" :style="{ display: download }">
              <span @click="skips('展馆平面图','datadownload')">{{ $t("language.HallPlan") }}</span>
              <span @click="skips('场地动线图','datadownload')">{{ $t("language.SiteDynamicLineMap") }}</span>
              <span @click="skips('宣传手册','datadownload')">{{ $t("language.Brochure") }}</span>
              <span @click="skips('管理规定','datadownload')">{{ $t("language.ManagementRegulations") }}</span>
              <span @click="skips('报批文件','datadownload')">{{ $t("language.ApprovalDocument") }}</span>
            </span>
          </p>
          <!-- 周边服务 -->
          <p @mouseenter="open(6)" @mouseleave="close">
            <span @click="skips('/','traffic')" v-html="$t('language.PeripheralServices')"></span><i class="el-icon-arrow-down"></i
            ><span class="open" :style="{ display: ambitus }">
              <span @click="skips('交通指南','traffic')">{{ $t("language.TrafficGuide") }}</span>
              <span @click="skips('停车信息','traffic')">{{ $t("language.ParkingInformation") }}</span>
              <span @click="skips('周边酒店','traffic')">{{ $t("language.SurroundingHotels") }}</span>
              <span @click="skips('旅游景点','traffic')">{{ $t("language.ScenicSpot") }}</span>
            </span>
          </p>
          <p>
            <!-- <span @click="chenge(2)" v-html="$t('language.Chinese')"></span> |
            <span @click="chenge(1)" v-html="$t('language.En')"></span> -->
          </p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      backgrounds: require("../assets/image/banners.jpg"),

      opacitys: 0,
      opacity: 1,
      opacitya: 1,
      ablout: "none",
      fingerpost: "none",
      dynamic: "none",
      serve: "none",
      download: "none",
      ambitus: "none",
      display: "block",
      long:"",
      router:'',
      windowheight:"",
      n:0
    };
  },
  created() {
    // 获取页面高度
    this.windowheight = `${document.body.clientHeight}px`
    // 加载首次进入页面导航淡入
    setTimeout(() => {
      var int = setInterval(() => {
        this.opacitys = this.opacitys + 0.1;
        if (this.opacitys > 1) {
          clearInterval(int);
          return;
        }
      }, 200);
    }, 1000);
  },
  mounted() {

  },
  methods: {
    // 点击logo返回首页面
    goindexpage(){
      this.$router.push('/index')
    },
    // 鼠标移出关闭二级导航
    close() {
      this.ablout = "none";
      this.fingerpost = "none";
      this.dynamic = "none";
      this.serve = "none";
      this.download = "none";
      this.ambitus = "none";
    },
    // 鼠标划上显示二级导航
    open(val) {
      if (val == 1) {
        this.ablout = "block";
        this.fingerpost = "none";
        this.dynamic = "none";
        this.serve = "none";
        this.download = "none";
        this.ambitus = "none";
      } else if (val == 2) {
        this.ablout = "none";
        this.dynamic = "none";
        this.serve = "none";
        this.download = "none";
        this.ambitus = "none";
        this.fingerpost = "block";
      } else if (val == 3) {
        this.ablout = "none";
        this.fingerpost = "none";
        this.serve = "none";
        this.download = "none";
        this.ambitus = "none";
        this.dynamic = "block";
      } else if (val == 4) {
        this.ablout = "none";
        this.fingerpost = "none";
        this.dynamic = "none";
        this.download = "none";
        this.ambitus = "none";
        this.serve = "block";
      } else if (val == 5) {
        this.ablout = "none";
        this.fingerpost = "none";
        this.dynamic = "none";
        this.serve = "none";
        this.ambitus = "none";
        this.download = "block";
      } else if (val == 6) {
        this.ablout = "none";
        this.fingerpost = "none";
        this.dynamic = "none";
        this.serve = "none";
        this.download = "none";
        this.ambitus = "block";
      }
    },
    toVr(){
      window.open("http://www.ybicec.com/vr/", '_blank')
    },
    // 页面跳转
    skips(val,title){
      if(this.$route.path != `/${title}`){
          this.$router.push({path:title,query:{path:val}})
      }else{
        this.$router.push({path:title,query:{path:val}})
        history.go(0)
      }
    },
    // 语言切换
    chenge(val) {
      this.$confirm("确定切换语言吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        if (val == 1) {
          window.localStorage.setItem("locale","en-US")
          this.long = "en-US"
          this.$i18n.locale = this.long; //关键语句
          history.go(0)
        } else {
          window.localStorage.setItem("locale","zh-CN")
          this.long = "zh-CN"
          this.$i18n.locale = this.long; //关键语句
          history.go(0)
        }
      });
    },
  },
};
</script>

<style scoped>
.add{
  position: relative;
  width: 100%;
  height: 100%;
}
.logo:hover{
  cursor:pointer
}
.el-icon-arrow-down{
  margin-left: 5px;
}
@keyframes example {
  0% {
    background: url('../assets/image/banners1.png');
    background-size: 100% 100%;
  }
  /* 34% {
    background: url('../assets/image/cbc627be8ed6f28e1d956c08a1d28a1.jpg');
    background-size: 100% 100%;
  } */
  50% {
    background: url('../assets/image/lADPDgtYx81R3yrNA5bNB4A_1920_918.jpg');
    background-size: 100% 100%;
  }
 100% {
    background: url('../assets/image/banners1.png');
    background-size: 100% 100%;
  }

}
a {
  color: white;
}
.nex {
  position: absolute;
  color: #fff;
  right: 20px;
  top: 30px;
}
.car {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: auto;
}
.slideshow {
  background: url('../assets/image/banners1.png'),url('../assets/image/lADPDgtYx81R3yrNA5bNB4A_1920_918.jpg');
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation-iteration-count: infinite;
  animation-name: example;
  animation-duration: 13s;
  /* animation-timing-function:ease; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.nav {
  width: 100%;
  height: 75px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0,0,0,0.3);/* r:red g:green b:blue a:alpha(半透明):0-1,1是不透明，0是全透明 */
            color: white;
}
.logo {
  width: 352px;
  height: 46px;
  float: left;
  margin: 14px 0 0 50px;
}
.navcont {
  width: 1080px;
  height: 100%;
  float: right;
  margin-right:30px;
}
.navcont p {
  float: left;
  cursor: pointer;
  text-align: center;
  height: 100%;
  /* border-bottom: 1px solid #aaaeb1; */
  margin-right: 62px;
  line-height: 75px;
  color: #fff;
  position: relative;
}
.navcont p:nth-last-of-type(1) {
  margin-right: 0px;
}
.navcont img {
  margin-left: 11px;
}
.imgs {
  position: absolute;
  bottom: 40px;
  /* animation: example 3s; */
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
}
.open span {
  display: block;
  width: 215px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}
.open span:hover {
  background: #1f5579;
}
.open {
  width: 215px;
  background: rgba(1, 5, 8, 0.3);
  z-index: 100;
  display: block;
  position: absolute;
  left: -55px;
  font-size: 13px;
}
</style>
